'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { currencies } from '@constants'
import { getSearchParams } from "@utils";
import { RadioGroup } from '@headlessui/react'
import { getCookie, setCookie } from 'cookies-next';
import { Listbox, Transition } from '@headlessui/react'
import { MdCurrencyExchange } from "react-icons/md";

const CurrencyTab = ({ title, lang }) => {
    const router = useRouter();

    const [currency, setCurrencyType] = useState('thb')
    const [curren, setCurrency] = useState(currencies[lang][0])
    let [addChecked, setAddChecked] = useState(currencies[lang][0].value)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        let currencyType = getCookie('currency')
        if (currencyType == undefined) {
            currencyType = 'thb'
        } else {
            setCurrencyType(currencyType)
            setAddChecked(currencyType)
        }

    })

    const openExchangeTab = () => {
        setIsOpen(prevValue => !prevValue)
        let currencyType = getCookie('currency')
        setAddChecked(currencyType)

    }

    const handleUpdateParams = (e) => {
        setCookie('currency', e.value)
        setCurrencyType(e.value)
        router.refresh()

    };

    useEffect(() => {
        const currencyValue = getSearchParams('currency')
        setAddChecked(currencyValue)

        const newValue = currencies[lang].find(obj => obj.value === addChecked);
        // console.log('new value ' + newValue)
        if (newValue != undefined) {
            setCurrency(newValue)
            setCurrencyType(newValue.value)

        }
    }, [handleUpdateParams])

    return (
        // <div className='relative mr-4'>
        //     <div onClick={openExchangeTab} className='cursor-pointer text-light border-[1px] border-light border-solid p-1 rounded-sm' >{currency.toUpperCase()}</div>
        //     {isOpen ? (
        //         <div className='absolute flex flex-col w-[200px] p-2 bg-light border-[1px] border-secondary border-solid z-[1001] rounded-sm'>
        //             <div>
        //                 {/* <form onSubmit={handleSubmit}> */}
        //                 <RadioGroup value={curren} onChange={(value) => {
        //                     setCurrency(value)
        //                     handleUpdateParams(value)
        //                 }}
        //                     className='w-full py-1 mt-1 flex flex-col'
        //                 >
        //                     {currencies[lang].map((curr) => (
        //                         <RadioGroup.Option
        //                             key={curr.value}
        //                             value={curr}
        //                             // checked={curr === curr}
        //                             className={({ active, checked }) =>
        //                                 `${active
        //                                     ? ''
        //                                     : ''
        //                                 }
        //                             ${checked ? 'bg-maincolor text-light' : 'bg-light'
        //                                 }
        //                             w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
        //                             }
        //                         >
        //                             {({ active, checked }) => (
        //                                 <>
        //                                     <div className="flex w-full items-center justify-between">
        //                                         <div className="flex items-center">
        //                                             <div className="text-sm text-center">
        //                                                 <RadioGroup.Label
        //                                                     as="p"
        //                                                     className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
        //                                                         }`}
        //                                                 >
        //                                                     {curr.value.toUpperCase()} - {curr.title}
        //                                                 </RadioGroup.Label>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </>
        //                             )}
        //                         </RadioGroup.Option>
        //                     ))}
        //                 </RadioGroup>
        //             </div>
        //         </div>
        //     ) : null}
        // </div>
        <div className="relative z-[100]">
            <Listbox 
                value={curren} 
                onChange={(value) => {
                    setCurrency(value)
                    handleUpdateParams(value)
                }}
            >
            <div className="z-[100]">
                    <Listbox.Button className="cursor-pointer text-light text-center border-[1px] border-light border-solid p-1 flex items-center rounded-sm mr-3">
                        <MdCurrencyExchange color='white' /><span className="ml-2 truncate text-light">{curren.value.toUpperCase()}</span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute  mt-1 w-[200px] py-1 bg-light overflow-hidden shadow-lg z-[1001] rounded-md">
                        {currencies[lang].map((curr) => (
                            <Listbox.Option
                            key={curr.value}
                            className={({ active }) =>
                                `w-full relative flex cursor-pointer focus:outline-none ${
                                active ? 'font-medium w-full bg-maincolor text-light' : 'text-maincolor'
                                }`
                            }
                            value={curr}
                            >
                                <div className='w-full py-2 text-center text-sm rounded-md  hover:text-light'>
                                    <span>
                                        {curr.value.toUpperCase()} - {curr.title}
                                    </span>
                                </div>
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
            </div>
            </Listbox>
        </div>
    )
}

export default CurrencyTab
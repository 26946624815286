'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FaGlobe } from "react-icons/fa";

export default function MobileLocaleSwitcher({ language, dictionary }) {
  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()
  
  const redirectedPathName = (locale) => {
    const languages = ['en', 'cn', 'th']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)


  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="relative w-full">
    <Listbox 
        // value={curren} 
        // onChange={(value) => {
        //     setCurrency(value)
        //     handleUpdateParams(value)
        // }}
    >
    <div className="w-full">
            <Listbox.Button className="w-full cursor-pointer text-maincolor text-center border-[1px] border-light border-solid p-1 flex items-center rounded-sm mr-3">
                <FaGlobe color='maincolor' /><span className="ml-2 truncate text-maincolor">{dictionary[language]}</span>
            </Listbox.Button>
            <Listbox.Options className="w-full py-1 bg-light overflow-hidden rounded-md">
            {i18n.locales.map((locale) => {
               if (locale !== 'default') {
                return (
                  <Link className='w-1/2' key={locale} onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>
                      <div className={`w-full py-1 pl-2 rounded-md ${locale == language ? 'bg-maincolor text-light' : 'bg-light text-maincolor'}`}>
                        {dictionary[locale]} ({locale.toUpperCase()})
                    </div>
                  </Link>
                ) 
              }
            })}
            </Listbox.Options>
    </div>
    </Listbox>
</div>
    // <div className="relative w-full flex flex-row py-4">
    //         {i18n.locales.map((locale) => {
    //            if (locale !== 'default') {
    //             return (
    //               <Link className='w-1/2' key={locale} onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>
    //                   <div className={`w-full py-2 text-center text-xl rounded-md ${locale == language ? 'bg-maincolor text-light' : 'bg-light text-maincolor border-[1px] border-maincolor'}`}>
    //                     {locale !== 'cn' ? locale.toUpperCase() : '中文'}
    //                 </div>
    //               </Link>
    //             ) 
    //           }
    //         })}
    // </div>
  )
}
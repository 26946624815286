'use client'
import React, { useState, useEffect } from 'react'
import { signIn, signOut, useSession, getProviders } from 'next-auth/react'
import Link from 'next/link'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import LocaleSwitcher from './locale-switcher'
import MobileLocaleSwitcher from './mobile-locale-switcher'
import MobileCurrencySwitcher from './mobile-currency-switcher'
import MobileLanguageSetter from './mobile-language-setter'
import { FaHome, FaSearch, FaHeart, FaUser } from "react-icons/fa"
import CurrencyTab from './CurrencyTab'
import tailwindConfig from '@tailwind.config'
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation'
import { IoMenu, IoClose } from "react-icons/io5";
import { Dialog } from '@headlessui/react'

const Nav = ({ dictionary, lang, isViewPage = false }) => {
    const currentRoute = usePathname();
    const router = useRouter()

    const { data: session } = useSession()

    const [language, setLanguage] = useState(lang)
    const [providers, setProviders] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('/')
    const [isMap, setIsMap] = useState(false)


    useEffect(() => {
        const setUpProviders = async () => {
            const response = await getProviders()

            setProviders(response)
        }

        setUpProviders()
    }, [])

    useEffect(() => {
        const setupUrl = () => {
            const url = currentRoute
            // console.log(url)
            setUrl(url)
        }

        setupUrl()
        router.refresh()
    }, [currentRoute])

  return (
    <nav className="w-full  bg-maincolor flex items-center justify-between flex-wrap p-0">
    <div className='w-full sm:flex hidden 2xl:w-8/12 items-center justify-between mx-auto p-5'>
        <div className="flex items-center flex-shrink-0 text-white mr-6">

            <LinkComponent href="/" className='relative h-8 w-20'>
                <Image
                    className='object-cover'
                    src={'/logo.png'}
                    // width={imageWidth}
                    // height={imageHeight}
                    alt="Logo"
                    fill={true}
                />


            </LinkComponent>

        </div>
        <div className="flex w-full items-center lg:w-auto">
            <CurrencyTab title="currency" lang={lang} />
            <LocaleSwitcher language={language}/>
            <LinkComponent href="/">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.home}
                </span>
            </LinkComponent>
            <LinkComponent href="/properties">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.forRent}
                </span>
            </LinkComponent>
            <LinkComponent href="/sell">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.listWithUs}
                </span>
            </LinkComponent>
            <LinkComponent href="/new-listings">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.news}
                </span>
            </LinkComponent>
            <LinkComponent href="/contact-us">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.contactUs}
                </span>
            </LinkComponent>
            {session?.user ? (
                <div className='flex gap-3 md:gap-5'>
                    <LinkComponent href="/profile">
                        <span className='text-light'>
                            {dictionary.myProfile}
                        </span>
                    </LinkComponent>
                    <button type="button" onClick={signOut}>
                        <span className='text-light'>
                            {dictionary.signOut}
                        </span>
                    </button>
                    {/* <LinkComponent href="/profile">
                    </LinkComponent> */}
                </div>
            ) : (
                <>
                    <button onClick={() => signIn()}><span className='text-light'>{dictionary.signIn}</span></button>
                </>
            )}
        </div>
    </div>
    {/* Mobile Nav */}
    <>
        <div className='sm:hidden w-full flex items-center justify-between relative p-1 pl-2 z-[120]'>
            <LinkComponent href={'/'}>
                <div className='relative h-6 w-14'>
                    <Image
                        className='object-cover'
                        src={'/logo.png'}
                        alt="Logo"
                        fill={true}
                    />
                </div>
            </LinkComponent>
            <MobileLanguageSetter language={lang} />
            <button onClick={() => setIsOpen(true)}>                    
                <IoMenu 
                    className='border-[1px] border-light rounded-md'
                    size={32}
                    color={tailwindConfig.theme.extend.colors.light}
                /></button>
            {/* <Popover className="relative">
                <Popover.Button>
                    <IoMenu 
                        className='border-[1px] border-light rounded-md'
                        size={25}
                        color={tailwindConfig.theme.extend.colors.light}
                    />
                </Popover.Button>
                <Popover.Panel className='absolute w-[280px] top-0 right-0 z-50 bg-white'>
                    <div className='w-full flex justify-end p-1'>
                        <Popover.Button>
                            <IoMenu 
                                className='border-[1px] border-light bg-black rounded-md'
                                size={25}
                                color={tailwindConfig.theme.extend.colors.light}
                            />
                        </Popover.Button>
                    </div>

                    <div>hi</div>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />

                </Popover.Panel>
            </Popover> */}
                {/* <Popover className='relative'>
      {({ open }) => (
        <>
                <Popover.Button>
                    <IoMenu 
                        className='border-[1px] border-light rounded-md'
                        size={25}
                        color={tailwindConfig.theme.extend.colors.light}
                    />
                </Popover.Button>

                <Popover.Panel className='absolute z-[120] w-[280px] top-0 right-0 bg-white'>
                    <div className='w-full flex justify-end p-1 z-[120]' >
                        <Popover.Button>
                            <IoMenu 
                                className='border-[1px] border-light bg-black rounded-md'
                                size={25}
                                color={tailwindConfig.theme.extend.colors.light}
                            />
                        </Popover.Button>
                    </div>

                    <div>hi</div>
                    <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />

                </Popover.Panel>
                <Popover.Overlay className="fixed z-[100] inset-0 bg-black opacity-30" />

        </>
      )}
    </Popover> */}
            <Dialog 
                className='z-[120]' 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
            >
                <div className="fixed  inset-0 bg-black/30 z-[120]" aria-hidden="true" />
                <Dialog.Panel className='bg-white w-[280px] z-[121] absolute top-0 right-0 rounded-l-md rounded-b-md'>
                    <div className='w-full flex justify-end p-1'>
                        <button onClick={() => setIsOpen(false)}>                    
                            <IoClose
                                className='border-[1px] border-light bg-maincolor rounded-md'
                                size={32}
                                color={tailwindConfig.theme.extend.colors.light}
                            />
                        </button>
                    </div>
                    <div className='p-2 w-full'>
                        <Dialog.Title className='text-maincolor pb-2 mb-2 border-b-[1px] border-forth'>
                            <LinkComponent href={`/`} className='hover:color-forth'>
                                {dictionary.title}
                            </LinkComponent>
                        </Dialog.Title>
                        <div className='flex flex-col mb-2'>
                            <p className='text-sm text-secondary'>{dictionary.language}</p>
                            <div className='sm:hidden flex'>
                                <MobileLocaleSwitcher language={lang} dictionary={dictionary} />
                            </div>
                        </div>
                        <div className='flex flex-col mb-2'>
                            <p className='text-sm text-secondary'>{dictionary.currency}</p>
                            <div className='sm:hidden flex'>
                                <MobileCurrencySwitcher lang={lang} />
                            </div>
                            
                        </div>
                        <div className='w-full bg-pink border-b-[1px] border-forth mb-2'></div>
                        <ul className='w-full flex text-maincolor flex-col mb-4 md:mb-0 md:pl-0'>
                            <LinkComponent href={`/about`} className='w-full hover:color-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.about}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/contact-us`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.contactUs}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/new-listings`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.newestListings}
                                </li>
                            </LinkComponent>
                        </ul>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>
        {!isMap && !isViewPage && (
            <div className='sm:hidden z-50 w-full bg-maincolor flex justify-between bottom-0 fixed border-t px-5 pt-2 pb-2 border-t-secondary'>
                <div>
                    <LinkComponent
                        href="/"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/' ? (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/properties"
                        className='flex flex-col items-center justify-center'
                    >
                        {url !== '/' && url !== '/profile' && url !== '/likes' ? (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}

                                />
                                <span className='text-light text-sm'>
                                    {dictionary.search}
                                </span></>
                        ) : (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}

                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.search}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/likes"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/likes' ? (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        )}
                    </LinkComponent>
                </div>
                <div>
                    {session?.user ? (
                        <LinkComponent
                            href="/profile"
                            className='flex flex-col items-center justify-center'
                        >
                            <Image
                                src={session?.user?.image}
                                alt='profile'
                                width={25}
                                height={25}
                                className='object-contain rounded-full'
                            />
                            {url == '/profile' ? (
                                <span className='text-light text-sm'>
                                    {dictionary.profile}
                                </span>
                            ) : (
                                <span className='text-forth text-sm'>
                                    {dictionary.profile}
                                </span>
                            )}
                        </LinkComponent>

                    ) : (
                        // <button onClick={() => signIn()}><span className='text-black'>Sign in</span></button>
                        <div
                            // href="/profile"
                            className='flex flex-col items-center justify-center'
                            onClick={() => signIn()}
                        >
                            {url == '/profile' ? (
                                <>
                                    <FaUser
                                        size={25}
                                        color={tailwindConfig.theme.extend.colors.light}
                                    />
                                    <span className='text-light text-sm'>
                                        {dictionary.profile}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <FaUser
                                        size={25}
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                    <span className='text-forth text-sm'>
                                        {dictionary.profile}
                                    </span>
                                </>
                            )}

                        </div>
                    )
                    }
                </div>
            </div>
        )}
    </>
</nav>
    // <>
    //     <div className={`sm:block hidden ${(url == '/' || url == '/en' || url == '/th') ? '' : 'bg-maincolor drop-shadow-md'} justify-center text-white text-xl z-[100]`}>
    //         <div className='2xl:w-8/12 mx-auto flex flex-row justify-between items-center py-2'>
    //             <div className=''>
    //                 <Link href={`/`}>
    //                     <Image
    //                         src={'/images/logo.png'}
    //                         height={36}
    //                         width={120}
    //                         // fill={"true"}
    //                         // priority={"true"}
    //                         // style={{
    //                         //     objectFit: 'cover'
    //                         // }}
    //                         alt="Banner Image"
    //                     />
    //                 </Link>
    //             </div>
    //             <div className='w-2/3 flex flex-row justify-between items-center'>
    //                 <LinkComponent href={`/properties`} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} title={dictionary.buy} />
    //                 <LinkComponent href={'/properties?saleType=rent'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} title={dictionary.rent} />
    //                 <LinkComponent href={'/projects'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} title={dictionary.projects} />
    //                 <LinkComponent href={'/blogs'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} title={dictionary.blog} />
    //                 <LinkComponent href={'/about'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} title={dictionary.about} />
    //                 <CurrencyTab title="currency" />
    //                 <LocaleSwitcher language={language}/>
    //             </div>
    //         </div>
    //     </div>
    //     {isOpen && (
    //         <div onClick={() => setIsOpen(!isOpen)} className='fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-50'></div>
    //     )}
    //     <div className={`flex sm:hidden ${(url == '/' || url == '/en' || url == '/th') ? '' : 'bg-maincolor drop-shadow-md'} w-full items-center justify-between`}>
    //         <Link href={`/`} className='w-full'>
    //             <div className='p-2'>
    //                 <Image
    //                     src={'/images/logo.png'}
    //                     height={48}
    //                     width={120}
    //                     // fill={"true"}
    //                     // priority={"true"}
    //                     style={{
    //                         objectFit: 'contain'
    //                     }}
    //                     alt="Banner Image"
    //                 />
    //             </div>
    //         </Link>
    //         <div 
    //             className='border-[1px] border-light m-2 rounded-md'
    //             onClick={() => setIsOpen(!isOpen)}
    //         >
    //             <IoMenu 
    //                 color={tailwindConfig.theme.extend.colors.light} 
    //                 size={48}
    //             />
    //         </div>
    //         {isOpen && (
    //             <>
    //                 <div className='absolute top-0 right-0 bg-light min-w-[300px] border-l-[1px] border-b-[1px] rounded-l-md'>
    //                     <div className='w-full flex justify-between items-center'>
    //                         <div></div>
    //                         <div 
    //                             className='border-[1px] border-maincolor m-2 rounded-md'
    //                             onClick={() => setIsOpen(!isOpen)}
    //                         >
    //                             <IoClose 
    //                                 color={tailwindConfig.theme.extend.colors.maincolor} 
    //                                 size={48}
    //                             />
    //                         </div>
    //                     </div>
    //                     <div className='flex flex-col pl-4'>
    //                         <LinkComponent href={`/properties`} className={`text-maincolor text-xl font-semibold py-2`} title={dictionary.buy} />
    //                         <LinkComponent href={`/properties?saleType=rent`} className={`text-maincolor text-xl font-semibold py-2`} title={dictionary.rent} />
    //                         <LinkComponent href={`/projects`} className={`text-maincolor text-xl font-semibold py-2`} title={dictionary.projects} />
    //                         <LinkComponent href={`/blog`} className={`text-maincolor text-xl font-semibold py-2`} title={dictionary.blog} />
    //                         <LinkComponent href={`/about`} className={`text-maincolor text-xl font-semibold py-2`} title={dictionary.about} />

    //                     </div>
    //                     <div>
    //                         <MobileLocaleSwitcher language={language} />
    //                     </div>
    //                 </div>
    //             </>
    //         )}
    //     </div>

    // </>
  )
}

export default Nav
const i18n = {
  defaultLocale: 'default',
  locales: ['default', 'en', 'cn', 'th'],
  localeDetection: false,
};

// Extracting the type using TypeScript syntax
// export type Locale = (typeof i18n)['locales'][number];
const Locale = i18n.locales;

module.exports = { i18n, Locale };
